import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MaxWidth from "../components/Layout/maxWidth"
import Heading from "../components/Layout/heading"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Heading>
      <h1>404: Not found</h1>
    </Heading>
    <MaxWidth>
      <p>
        This path does not exist. <Link to="/">Go back to home page</Link>
      </p>
    </MaxWidth>
  </Layout>
)

export default NotFoundPage
